import React, { useEffect, useState } from 'react';
import Table from '../../../common/components/Table';
import './BonificationScreen.scss';
import { BonificationResult, DateType } from '../../../types';
import { I18n } from 'react-redux-i18n';
import SearchBar from '../../../common/components/SearchBar';
import DateRangePicker from '../../../common/components/DateRangePicker';
import SelectMultiple from '../../../common/components/SelectMultiple';
import Button from '../../../common/components/Button';
import Checkbox from '../../../common/components/Checkbox';
import { parseDate } from '../../../utils';
import { ConfirmationModal } from '../../../common/components/Modal';
import {
    downloadBonifications,
    useGetBonifications,
    useUpdateDelivered,
    useUpdateRejected,
} from '../../service/BonificationService';
import Loader from '../../../common/components/Loader';
import { PaginationProps } from '../../../common/components/Table/Table';
import ReactTooltip from 'react-tooltip';
import commentsIcon from '../../../assets/icons/comments-icon.svg';

enum BONIFICATION_STATUS {
    DELIVERED = 'E',
    VALIDATED = 'V',
    REJECTED = 'R',
}

const defaultStatusFilter = [
    { id: BONIFICATION_STATUS.DELIVERED, checked: false },
    { id: BONIFICATION_STATUS.VALIDATED, checked: true },
    { id: BONIFICATION_STATUS.REJECTED, checked: false },
    { id: 'all', checked: false },
];

const BonificationScreen = () => {
    const [selected, setSelected] = useState<{ id: number }[]>([]);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [confirmationModalTitle, setConfirmationModalTitle] = useState<string>('');
    // filters for request
    const [textToSearch, setTextToSearch] = useState<string>('');
    const [statusFilter, setStatusFilter] =
        useState<{ id: string; checked: boolean }[]>(defaultStatusFilter);
    const [page, setPage] = useState<number>(0);
    const [searchDate, setSearchDate] = useState('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [sortProperty, setSortProperty] = useState<string>('');
    const { data: bonifications, isLoading } = useGetBonifications(
        statusFilter,
        page + 1,
        textToSearch,
        startDate,
        endDate,
        sortProperty ? (sortDirection === 'asc' ? `${sortProperty}` : `-${sortProperty}`) : '',
    );
    const {
        mutate: delivered,
        isLoading: isMakingDeliveredRequest,
        isSuccess: deliverSuccess,
    } = useUpdateDelivered();
    const {
        mutate: rejected,
        isLoading: isMakingRejectRequest,
        isSuccess: rejectSuccess,
    } = useUpdateRejected();

    // reload data when filters change
    useEffect(() => {}, [
        statusFilter,
        page,
        textToSearch,
        startDate,
        endDate,
        sortProperty,
        sortDirection,
    ]);

    const handleStatusFilter = (status: string) => {
        const newStatusFilter = statusFilter.map((s) => {
            if (status === 'all') {
                if (statusFilter.find((s) => s.id === 'all')?.checked) {
                    return { ...s, checked: false };
                }
                return { ...s, checked: true };
            }
            if (s.id === status) {
                return { ...s, checked: !s.checked };
            }
            return s;
        });
        setStatusFilter(newStatusFilter);
    };

    const handleAllCheckbox = () => {
        if (bonifications) {
            const notDelivered = bonifications.results.filter(
                (r) =>
                    r.estado !== BONIFICATION_STATUS.DELIVERED &&
                    r.estado !== BONIFICATION_STATUS.REJECTED,
            );
            setAllChecked(!allChecked);
            const newChecked = allChecked ? [] : notDelivered.map((r) => ({ id: r.id }));
            setSelected(newChecked);
        }
    };

    const handleCheckBox = (id: number) => {
        const checkedValues = selected.find((r) => r.id === id)
            ? selected.filter((r) => r.id !== id)
            : [...selected, { id }];
        setSelected(checkedValues);
    };

    const handleSearch = (text: string) => {
        setTextToSearch(text);
        setPage(0);
    };

    useEffect(() => {
        if (deliverSuccess || rejectSuccess) {
            setSelected([]);
            setAllChecked(false);
            setConfirmationModalTitle('');
        }
    }, [deliverSuccess, rejectSuccess]);

    const handleReject = () => {
        rejected(selected);
    };

    const handleDeliver = () => {
        delivered(selected);
    };

    const handleDownload = () => {
        downloadBonifications(bonifications ? bonifications.results : []);
    };

    const renderDates = (dates: DateType[]) => {
        const getDateWithStatus = (date: DateType) => {
            return `${parseDate(new Date(date.created_at))} ${I18n.t(
                `bonifications.table.status.${date.estado}`,
            )}`;
        };

        const allDates = dates
            .map((date) => `<span>${getDateWithStatus(date)}</span><br>`)
            .join('');

        return (
            <div>
                <a data-tip={allDates} data-html={true} data-for='bonifications-date-tooltip'>
                    <span className='text'>
                        {parseDate(new Date(dates[dates.length - 1].created_at))}
                    </span>
                </a>
                <ReactTooltip
                    id='bonifications-date-tooltip'
                    backgroundColor='#ffffff'
                    effect='solid'
                    className='bonification-tooltip paragraph'
                />
            </div>
        );
    };

    const handleSort = (property: string) => {
        if (sortProperty === property) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortProperty(property);
            setSortDirection('asc');
        }
        setPage(0);
    };

    const getArrow = (property: string) => {
        if (sortProperty === property) {
            return sortDirection === 'asc' ? '↑' : '↓';
        }
        return null;
    };

    const getStatusClassnameColor = (status: string) => {
        switch (status) {
            case BONIFICATION_STATUS.DELIVERED:
                return '';
            case BONIFICATION_STATUS.VALIDATED:
                return 'A';
            case BONIFICATION_STATUS.REJECTED:
                return 'B';
            default:
                return '';
        }
    };

    const renderComment = (comment: string) => {
        return (
            <div>
                <a data-tip={comment} data-for='bonifications-comment-tooltip'>
                    <img src={commentsIcon} alt='Comment Icon'></img>
                </a>
                <ReactTooltip
                    id='bonifications-comment-tooltip'
                    backgroundColor='#ffffff'
                    effect='solid'
                    className='bonification-tooltip paragraph'
                />
            </div>
        );
    };

    const commentMock =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';

    const getColumns = () => {
        return [
            {
                id: 'id',
                Header: () => (
                    <div onClick={() => handleSort('id')}>
                        <h5>{I18n.t('bonifications.table.headers.id')}</h5>
                        {getArrow('id')}
                    </div>
                ),
                accessor: 'id',
                Cell: ({ value }: { value: number }) => <span className='text'>{value}</span>,
            },
            {
                id: 'patientId',
                Header: () => (
                    <div onClick={() => handleSort('paciente__id')}>
                        <h5>{I18n.t('bonifications.table.headers.patientId')}</h5>
                        {getArrow('paciente__id')}
                    </div>
                ),
                accessor: 'paciente.id',
                Cell: ({ value }: { value: number }) => <span className='text'>{value}</span>,
            },
            {
                id: 'os',
                Header: () => (
                    <div onClick={() => handleSort('obra_social__razon_social')}>
                        <h5>{I18n.t('bonifications.table.headers.os')}</h5>
                        {getArrow('obra_social__razon_social')}
                    </div>
                ),
                accessor: 'paciente.obra_social',
                Cell: ({ value }: { value: string }) => <span className='text'>{value}</span>,
            },
            {
                id: 'dates',
                Header: () => (
                    <div onClick={() => handleSort('updated_at')}>
                        <h5>{I18n.t('bonifications.table.headers.date')}</h5>
                        {getArrow('updated_at')}
                    </div>
                ),
                accessor: 'fechas',
                Cell: ({ value }: { value: DateType[] }) => renderDates(value),
            },
            {
                id: 'contract',
                Header: () => (
                    <div onClick={() => handleSort('contrato__descripcion')}>
                        <h5>{I18n.t('bonifications.table.headers.contract')}</h5>
                        {getArrow('contrato__descripcion')}
                    </div>
                ),
                accessor: 'contrato.descripcion',
                Cell: ({ value }: { value: string }) => <span className='text'>{value}</span>,
            },
            {
                id: 'product',
                Header: () => (
                    <div onClick={() => handleSort('producto__denominacion')}>
                        <h5>{I18n.t('bonifications.table.headers.product')}</h5>
                        {getArrow('producto__denominacion')}
                    </div>
                ),
                accessor: 'denominacion',
                Cell: ({ value }: { value: string }) => <span className='text'>{value}</span>,
            },
            {
                id: 'cicleNumber',
                Header: () => (
                    <div onClick={() => handleSort('ciclo')}>
                        <h5>{I18n.t('bonifications.table.headers.cicle')}</h5>
                        {getArrow('ciclo')}
                    </div>
                ),
                accessor: 'ciclo',
                Cell: ({ value }: { value: number }) => <span className='text'>{value}</span>,
            },
            {
                id: 'comments',
                Header: () => <h5>{I18n.t('bonifications.table.headers.comments')}</h5>,
                accessor: 'comentarios',
                Cell: ({ value }: { value: string }) => renderComment(commentMock),
            },
            {
                id: 'status',
                Header: () => <h5>{I18n.t('bonifications.table.headers.status')}</h5>,
                accessor: 'estado',
                Cell: ({ value }: { value: string }) => (
                    <span className={`text status ${getStatusClassnameColor(value)}`}>
                        {I18n.t(`bonifications.table.status.${value}`)}
                    </span>
                ),
                sortable: false,
            },
            {
                id: 'actions',
                Header: () => (
                    <div className='header-checkbox'>
                        <h5>{I18n.t('bonifications.table.headers.action')}</h5>
                        <Checkbox checked={allChecked} onChange={() => handleAllCheckbox()} />
                    </div>
                ),
                accessor: 'estado',
                Cell: ({ value, row }: { value: string; row: BonificationResult }) => (
                    <div className='checkbox-cell'>
                        <Checkbox
                            disabled={
                                value === BONIFICATION_STATUS.REJECTED ||
                                value === BONIFICATION_STATUS.DELIVERED
                            }
                            checked={!!selected.find((r) => r.id === row.id)}
                            onChange={() => handleCheckBox(row.id)}
                        />
                    </div>
                ),
                sortable: false,
            },
        ];
    };

    const pagination: PaginationProps = {
        page: page,
        total: bonifications ? bonifications.count : 0,
        totalPages: bonifications ? bonifications.total_pages : 0,
        onPageChange: (page: number) => setPage(page),
        pagedMessageTranslation: 'bonifications.table.pagedMessage',
        pagedMessageEmptyTranslation: 'bonifications.table.pagedMessageEmpty',
    };
    return (
        <div className='bonification-screen'>
            <div className='header'>
                <div className='header-bar'>
                    <span className='title'>{I18n.t('bonifications.title')}</span>
                    <SearchBar textToSearch={textToSearch} onSearch={(e) => handleSearch(e)} />
                    <DateRangePicker
                        textToSearch={searchDate}
                        allTransactions={bonifications ? bonifications.results : []}
                        searchText={(e) => setSearchDate(e)}
                        updateFilteredTransactionsByDateRange={() => {}}
                        clearDateRangeSelection={() => {
                            setStartDate('');
                            setEndDate('');
                            setPage(0);
                        }}
                        startDate={setStartDate}
                        endDate={setEndDate}
                        setPage={setPage}
                    />
                    <SelectMultiple
                        placeholder={I18n.t('bonifications.table.headers.status')}
                        values={statusFilter}
                        className='rounded'
                        onChange={(id: string) => handleStatusFilter(id)}
                        options={[
                            {
                                value: BONIFICATION_STATUS.DELIVERED,
                                label: I18n.t(
                                    `bonifications.table.status.${BONIFICATION_STATUS.DELIVERED}`,
                                ),
                            },
                            {
                                value: BONIFICATION_STATUS.VALIDATED,
                                label: I18n.t(
                                    `bonifications.table.status.${BONIFICATION_STATUS.VALIDATED}`,
                                ),
                            },
                            {
                                value: BONIFICATION_STATUS.REJECTED,
                                label: I18n.t(
                                    `bonifications.table.status.${BONIFICATION_STATUS.REJECTED}`,
                                ),
                            },
                            {
                                value: 'all',
                                label: I18n.t('bonifications.table.status.all'),
                            },
                        ]}
                    />
                    <Button
                        type='button'
                        className='primary icon-size download-button'
                        onClick={handleDownload}
                        loading={false}
                        icon={<i className='icon-cloud-download' />}
                    />
                    <div className='action-button-container'>
                        <Button
                            type='button'
                            className='third tiny'
                            onClick={() =>
                                setConfirmationModalTitle(I18n.t('bonifications.deliver'))
                            }
                            loading={false}
                            text={
                                I18n.t('bonifications.deliver') +
                                (selected.length > 0 ? ` (${selected.length})` : '')
                            }
                            disabled={selected.length === 0}
                        />
                        <Button
                            type='button'
                            className='error tiny'
                            onClick={() =>
                                setConfirmationModalTitle(I18n.t('bonifications.reject'))
                            }
                            loading={false}
                            text={
                                I18n.t('bonifications.reject') +
                                (selected.length > 0 ? ` (${selected.length})` : '')
                            }
                            disabled={selected.length === 0}
                        />
                        <ConfirmationModal
                            show={confirmationModalTitle !== ''}
                            title={confirmationModalTitle}
                            submit={
                                confirmationModalTitle === I18n.t('bonifications.deliver')
                                    ? handleDeliver
                                    : handleReject
                            }
                            cancel={() => setConfirmationModalTitle('')}
                        >
                            <div className='modal-text-container'>
                                {isMakingDeliveredRequest || isMakingRejectRequest ? (
                                    <Loader />
                                ) : (
                                    <span className='modal-text'>
                                        {confirmationModalTitle === I18n.t('bonifications.deliver')
                                            ? I18n.t('bonifications.modal.deliverMessage', {
                                                  bonificationQty: selected.length,
                                              })
                                            : I18n.t('bonifications.modal.rejectMessage', {
                                                  bonificationQty: selected.length,
                                              })}
                                    </span>
                                )}
                            </div>
                        </ConfirmationModal>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <Table
                    data={bonifications ? bonifications.results : []}
                    columns={getColumns()}
                    pagination={pagination}
                    noDataText={I18n.t('bonifications.table.noRows')}
                />
            )}
        </div>
    );
};

export default BonificationScreen;
